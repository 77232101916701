import React, { useEffect, useRef, useState } from 'react';
import SearchComponent from './SearchComponent';
import './MapComponent.css'; // Import CSS for styling

const MapComponent = ({
  onLocationSelect,
  savedLocation,
  lat = 12.976794,
  lng = 77.590092,
  setCurrentLocation,
}) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const loadGoogleMapsScript = (callback) => {
    const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
    if (existingScript) {
      if (window.google && window.google.maps) {
        callback();
      } else {
        existingScript.onload = callback;
      }
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    script.onerror = () => console.error('Failed to load Google Maps script.');
    document.body.appendChild(script);
  };

  useEffect(() => {
    setTimeout(() => {
      loadGoogleMapsScript(() => {
 
        if (window.google && window.google.maps) {
          const initialLocation = { lat, lng };
          const newMap = new window.google.maps.Map(mapRef.current, {
            center: initialLocation,
            zoom: 15,
            styles: [
              {
                elementType: 'geometry',
                stylers: [{ color: '#f5f5f5' }],
              },
              {
                elementType: 'labels.text.fill',
                stylers: [{ color: '#616161' }],
              },
              {
                elementType: 'labels.text.stroke',
                stylers: [{ color: '#f5f5f5' }],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#bdbdbd' }],
              },
              {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ color: '#eeeeee' }],
              },
              {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{ color: '#757575' }],
              },
            ],
          });
          setMap(newMap);
  
          const newMarker = new window.google.maps.Marker({
            position: initialLocation,
            map: newMap,
            draggable: true,
          });
          setMarker(newMarker);
  
          newMarker.addListener('dragend', (event) => {
            handleSaveLocation(event.latLng.lat(), event.latLng.lng());
          });
        } else {
          console.error('Google Maps library not available.');
        }
      });
    }, 500);
    
  }, [savedLocation, lat, lng]);

  const handleSaveLocation = (lat, lng) => {
    console.log('Lat', lat);
    console.log('Lng', lng);
    onLocationSelect({ lat, lng });
  };

  const handleLocationSelected = ({ lat, lng, city }) => {
    console.log('Selected Location:', { lat, lng, city });

    if (map && marker) {
      map.setCenter({ lat, lng });
      marker.setPosition({ lat, lng });
    }

    handleSaveLocation(lat, lng);
  };

  return (
    <div className="map-container">
      <h2 className="map-title">Select a Location</h2>
      <SearchComponent onLocationSelected={handleLocationSelected} />

      <div ref={mapRef} className="map-display" />

      <button className="btn-primary current-location mt-3" onClick={setCurrentLocation}>
        Use Current Location
      </button>
    </div>
  );
};

export default MapComponent;
