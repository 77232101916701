import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Booking.css';
import Header from '../components/Header';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BookingPage = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlotTime, setSelectedSlotTime] = useState('all');
    const [chartData, setChartData] = useState(null);
    const [viewMode, setViewMode] = useState('sales');
    const [summary, setSummary] = useState({ totalBookings: 0, totalRevenue: 0, popularSlot: '' });

    const getAllBooking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/booking`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const bookings = response.data.Booking;
            setBookings(bookings);
            setFilteredBookings(bookings);
            generateChartData(bookings);
            calculateSummary(bookings);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    useEffect(() => {
        getAllBooking();
    }, []);

    useEffect(() => {
        filterBookings();
    }, [selectedDate, selectedSlotTime, bookings]);

    const filterBookings = () => {
        let filtered = bookings;

        if (selectedDate) {
            filtered = filtered.filter((booking) => booking.bookingDate === selectedDate);
        }

        if (selectedSlotTime !== 'all') {
            filtered = filtered.filter((booking) => booking.slotStartTime === selectedSlotTime);
        }

        setFilteredBookings(filtered);
    };

    const calculateSummary = (bookings) => {
        const totalRevenue = bookings.reduce((sum, booking) => sum + booking.subscriptionPrice, 0);
        const totalBookings = bookings.length;

        const slotCounts = bookings.reduce((acc, booking) => {
            acc[booking.slotStartTime] = (acc[booking.slotStartTime] || 0) + 1;
            return acc;
        }, {});

        const popularSlot = Object.keys(slotCounts).reduce((a, b) => (slotCounts[a] > slotCounts[b] ? a : b), '');

        setSummary({ totalBookings, totalRevenue, popularSlot });
    };

    const generateChartData = (bookings) => {
        const bookingsByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.bookingDate).toLocaleDateString();
            acc[date] = acc[date] || { sales: 0, count: 0 };
            acc[date].sales += booking.subscriptionPrice;
            acc[date].count += 1;
            return acc;
        }, {});

        const dates = Object.keys(bookingsByDate);
        const data = dates.map((date) => bookingsByDate[date][viewMode]);

        setChartData({
            labels: dates,
            datasets: [
                {
                    label: viewMode === 'sales' ? 'Sales (INR)' : 'Bookings Count',
                    data,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        });
    };

    const getUniqueSlotTimes = () => {
        return [...new Set(bookings.map((booking) => booking.slotStartTime))];
    };

    return (
        <>
            <Header />
            <div className="container-fluid gym-display-container">
                <div className="card shadow-lg mb-4">
                    <div className="card-header bg-info text-white">
                        <h2 className="text-center mb-0">Booking Management</h2>
                    </div>
                    <div className="card-body">
                        {/* Summary Widget */}
                        <div className="row text-center mb-4">
                            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                <h5>Total Bookings</h5>
                                <p className="badge bg-primary fs-4">{summary.totalBookings}</p>
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                <h5>Total Revenue</h5>
                                <p className="badge bg-success fs-4">INR {summary.totalRevenue}</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Popular Slot</h5>
                                <p className="badge bg-warning fs-4">{summary.popularSlot || 'N/A'}</p>
                            </div>
                        </div>

                        {/* Filters */}
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <label htmlFor="dateSelect" className="form-label">Select Booking Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateSelect"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="slotSelect" className="form-label">Select Slot Timing</label>
                                <select
                                    className="form-select"
                                    id="slotSelect"
                                    value={selectedSlotTime}
                                    onChange={(e) => setSelectedSlotTime(e.target.value)}
                                >
                                    <option value="all">All Slots</option>
                                    {getUniqueSlotTimes().map((slotTime, index) => (
                                        <option key={index} value={slotTime}>
                                            {slotTime}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Chart */}
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                            <h5 className="mb-2 mb-md-0">Sales & Bookings Chart</h5>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => setViewMode(viewMode === 'sales' ? 'count' : 'sales')}
                            >
                                Toggle to {viewMode === 'sales' ? 'Bookings Count' : 'Sales'}
                            </button>
                        </div>
                        <div className="chart-container">
                            {chartData ? (
                                <Line
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: { legend: { position: 'top' } },
                                    }}
                                />
                            ) : (
                                <p>Loading chart...</p>
                            )}
                        </div>

                        {/* Table */}
                        {filteredBookings.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th scope="col">Booking ID</th>
                                            <th scope="col">User Full Name</th>
                                            <th scope="col">User Mobile Number</th>
                                            <th scope="col">Booking Date</th>
                                            <th scope="col">Visited</th>
                                            <th scope="col">Gym Rating</th>
                                            <th scope="col">Slot Start Time</th>
                                            <th scope="col">Subscription Price</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBookings.map((booking, index) => (
                                            <tr key={index}>
                                                <th scope="row">{booking.bookingId}</th>
                                                <td>{booking.userFullName}</td>
                                                <td>{booking.userMobileNumber}</td>
                                                <td>{new Date(booking.bookingDate).toLocaleDateString()}</td>
                                                <td>
                                                    {booking.visited ? (
                                                        <span className="badge bg-success">Visited</span>
                                                    ) : (
                                                        <span className="badge bg-danger">Not Visited</span>
                                                    )}
                                                </td>
                                                <td>{booking.gymRating}</td>
                                                <td>{booking.slotStartTime}</td>
                                                <td>INR {booking.subscriptionPrice}</td>
                                                <td>{new Date(booking.createdAt).toLocaleDateString()}</td>
                                                <td>{booking.isPaid ? <span className="badge bg-success">Paid</span> : <span className="badge bg-danger">Not Paid</span>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-center">No bookings found for the selected filters.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookingPage;

