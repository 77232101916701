import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginWithPin.css';
import Header from '../components/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordData.password !== passwordData.confirmPassword) {
      toast.error('Passwords do not match. Please try again.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/reset-password`,
        {
          email,
          token,
          password: passwordData.password,
          confirmPassword: passwordData.confirmPassword
        }
      );

      if (response.data.message) {
        toast.success('Password has been reset successfully!');
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="reset-password-page">
      <Header />
      <div className="hero-overlay">
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow-lg">
                <div className="card-body">
                  <h3 className="card-title text-center text-light">
                    Reset Password
                  </h3>
                  <p className="text-light text-center">
                    Enter a new password below to reset your password.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label className="text-light">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={passwordData.password}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter new password"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-light">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        value={passwordData.confirmPassword}
                        onChange={handleInputChange}
                        required
                        placeholder="Confirm new password"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-warning w-100 mb-2"
                    >
                      Reset Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
