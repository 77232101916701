// PrivacyPolicy.js
import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Privacy.css';

const PrivacyPolicy = () => {
    return (
        <Container className="privacy-policy white-bg-container">
            <h1 className="text-center my-4">Privacy Policy</h1>

            <h2>1. Introduction</h2>
            <p>
                Welcome to Yupluck! We are committed to protecting your privacy and ensuring
                the security of any personal information you provide. This Privacy Policy outlines the types
                of information we collect and how it is used, especially regarding the use of camera access.
            </p>

            <h2>2. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> Such as name, email address, phone number, etc.</li>
                <li><strong>Usage Data:</strong> Information on how you interact with the app.</li>
                <li><strong>Camera Access:</strong> If you grant permission, we may access your device's camera to provide relevant app features.</li>
                <li><strong>Gallery Access:</strong> Gallery Access Policy
                                    Your privacy is important to us. This section explains how we handle access to and use of your device's gallery.

                                    1. Purpose of Access
                                    We may request access to your device's gallery for the following purposes:

                                    To allow you to upload images or videos for personalized app features.
                                    To enable sharing or customization of content within the app.
                                    2. Data Usage
                                    Uploaded Media: Any images or videos you select and upload through the app are used solely for the intended functionality, such as profile customization or sharing features.
                                    Temporary Access: The app only accesses the media you select and does not scan or collect your entire gallery content.
                                    3. Data Security
                                    Uploaded media is securely transmitted and stored on our servers (if applicable).
                                    We use industry-standard encryption to protect your data during transmission and storage.
                                    Media that is not uploaded remains exclusively on your device.
                                    4. Consent
                                    Gallery access is only enabled after you grant explicit permission.
                                    You may revoke this permission at any time through your device settings.
                                    5. Third-Party Sharing
                                    We do not share your gallery data with third parties without your explicit consent.
                                    If any third-party integrations require access (e.g., cloud storage services), this will be clearly disclosed.
                                    6. Your Rights
                                    You have the right to:

                                    Withdraw consent for gallery access at any time.
                                    Request deletion of uploaded media stored on our servers (if applicable)..</li>
                <li><strong>Location Access:</strong> If you grant permission, we may access your device's location to provide relevant near by product services.</li>
                <li><strong>OTP authentication:</strong> At yupluck, we value your privacy and are committed to ensuring your data is secure. Our application utilizes Twilio services for [e.g., SMS, voice calls, authentication, notifications, etc.]. This section outlines how Twilio interacts with your information.

                                                            1. What Information Does Twilio Process?
                                                            When you use features powered by Twilio in our application, Twilio may collect and process the following information:

                                                            Phone numbers used for communication (e.g., sending SMS or making calls)
                                                            2. How We Use Twilio Services
                                                            We use Twilio to:

                                                            [Authenticate users securely (e.g., OTP verification).]
                                                            [Send service-related notifications, such as alerts or updates.]
                                                            [Enable communication between users, staff, or other parties.]
                                                            Twilio acts as a third-party processor to facilitate these services, and we ensure that the data shared is minimal and used solely for the intended purpose.

                                                            

.</li>

            </ul>

            <h2>3. Camera Access and Usage</h2>
            <p>
                Our app may request access to your device's camera for certain features. This access will
                only be used for the following purposes:
            </p>
            <ul>
                <li>To scan QR codes, upload profile pictures, or use other camera-dependent features.</li>
                <li>To enhance the user experience by enabling in-app functionality that relies on camera access.</li>
            </ul>
            <p>
                <strong>Note:</strong> We do not store any images or video from your camera unless explicitly
                stated and only with your consent.
            </p>

            <h2>4. How We Use Your Information</h2>
            <p>
                We use the information we collect to provide, maintain, and improve our services, as well as to
                protect users. We do not sell your personal information to third parties.
            </p>

            <h2>5. Data Security</h2>
            <p>
                We take reasonable measures to protect your information from unauthorized access, but please
                remember that no method of transmission over the Internet or electronic storage is completely secure.
            </p>

            <h2>6. Your Choices</h2>
            <p>
                You can choose to disable camera access or revoke permissions at any time in your device settings.
                This may impact some features of the app, but you will still be able to use the core functions.
            </p>

            <h2 id="terms-and-conditions">7. Terms and Conditions</h2>
            <h3 id="acceptance-of-terms">7.1 Acceptance of Terms</h3>
            <p>
                By using YUPLUCK, you agree to these Terms and Conditions.
            </p>
            <h3>7.2 Platform Purpose</h3>
            <p>
                YUPLUCK serves as a platform connecting users with independently operated gyms (“Merchants”). 
                YUPLUCK is not the owner or operator of the gyms and does not provide fitness services. 
                All services booked through the App are fulfilled solely by the Merchants.
            </p>
            <h3>7.3 User Responsibilities</h3>
            <ul>
                <li>Provide accurate and up-to-date personal information when creating an account.</li>
                <li>Use the App solely for lawful purposes.</li>
                <li>Respect the rules and policies of the gyms you book through YUPLUCK.</li>
            </ul>
            <h3>7.4 Merchant Responsibilities</h3>
            <p>
                All gym services are provided solely by the Merchant or third-party owner. Merchants are 
                responsible for service quality, safety, and compliance with local laws.
            </p>
            <h3>7.5 Payments</h3>
            <ul>
                <li>Payments may be processed through YUPLUCK or directly by the Merchant.</li>
                <li>YUPLUCK is not liable for disputes related to payment, refunds, or cancellations made directly with the Merchant.</li>
            </ul>
            <h3>7.6 Booking and Availability</h3>
            <p>
                Bookings are subject to Merchant availability and operating hours.
            </p>
            <h3>7.7 Liability Disclaimer</h3>
            <p>
                YUPLUCK is not liable for injuries, damages, or losses sustained during gym usage. 
                Disputes must be resolved directly with the Merchant.
            </p>
            

            <h2>8. Cancellation and Refund Policy</h2>
            <p>
                If you wish to request a refund, please contact us by sending an email to{' '}
                <a href="mailto:contact@yupluck.com">contact@yupluck.com</a> with the details of your request. 
                Our team will review and respond accordingly.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy or our terms, please contact us at{' '}
                <a href="mailto:contact@yupluck.com">contact@yupluck.com</a>.
            </p>
            
        </Container>
    );
};

export default PrivacyPolicy;
