// PrivacyPolicy.js
import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Privacy.css';

const TermsAndCondition = () => {
    return (
        <Container className="privacy-policy white-bg-container">
            

            <h2 id="terms-and-conditions">1. Terms and Conditions</h2>
            <h3 id="acceptance-of-terms">1.1 Acceptance of Terms</h3>
            <p>
                By using YUPLUCK, you agree to these Terms and Conditions.
            </p>
            <h3>1.2 Platform Purpose</h3>
            <p>
                YUPLUCK serves as a platform connecting users with independently operated gyms (“Merchants”). 
                YUPLUCK is not the owner or operator of the gyms and does not provide fitness services. 
                All services booked through the App are fulfilled solely by the Merchants.
            </p>
            <h3>1.3 User Responsibilities</h3>
            <ul>
                <li>Provide accurate and up-to-date personal information when creating an account.</li>
                <li>Use the App solely for lawful purposes.</li>
                <li>Respect the rules and policies of the gyms you book through YUPLUCK.</li>
            </ul>
            <h3>1.4 Merchant Responsibilities</h3>
            <p>
                All gym services are provided solely by the Merchant or third-party owner. Merchants are 
                responsible for service quality, safety, and compliance with local laws.
            </p>
            <h3>1.5 Payments</h3>
            <ul>
                <li>Payments may be processed through YUPLUCK or directly by the Merchant.</li>
                <li>YUPLUCK is not liable for disputes related to payment, refunds, or cancellations made directly with the Merchant.</li>
            </ul>
            <h3>1.6 Booking and Availability</h3>
            <p>
                Bookings are subject to Merchant availability and operating hours.
            </p>
            
            
        </Container>
    );
};

export default TermsAndCondition;
