import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Modal, Button, Form, Container, Row, Col, ButtonGroup } from 'react-bootstrap';
import Header from '../components/Header';
import MapComponent from '../components/MapComponent'; 
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './GymImages.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';

const MergedGymPage = () => {
  // State Management
  const [gymData, setGymData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [images, setImages] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const navigate = useNavigate();
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);


  // Fetch Gym Data
  const fetchGymData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/fetch`, {
        headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
      });
      setLat(response.data.gym.latitude);
      setLng(response.data.gym.longitude);
      setGymData(response.data.gym);
    } catch (error) {
      console.error('Error fetching gym data:', error);
    }
  };

  // Fetch Gym Images
  const fetchImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/gym-images`, {
        headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
      });
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };


  const fetchStates = async () => {
    try {
      const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states', {
        country: 'India',
      });
      setStates(response.data.data.states);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  // Fetch cities based on selected state
  const fetchCities = async (state) => {
    try {
      const response = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: 'India',
        state,
      });
      setCities(response.data.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  useEffect(() => {
    fetchStates();
    fetchGymData();
    fetchImages();
  }, []);



  const handleShowEditModal = () => {
    setModalData({
      name: gymData?.name || '',
      description: gymData?.description || '',
      addressLine1: gymData?.addressLine1 || '',
      addressLine2: gymData?.addressLine2 || '',
      city: gymData?.city || '',
      state: gymData?.state || '',
      country: gymData?.country || '',
      pinCode: gymData?.pinCode || '',
      latitude: gymData?.latitude || '',
      longitude: gymData?.longitude || '',
    });
    fetchCities(gymData?.state);
    setShowEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'state') {
      fetchCities(value); // Fetch cities when state is selected
    }
    setModalData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    setShowMap(true);
    setIsMapModalOpen(true); // Set to true when the map modal is opened
  };

  const handleMapSave = async () => {
    try {
      console.log("modalData", modalData);
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/update`, modalData, {
        headers: { 
          'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") 
        }
      });
      setShowEditModal(false);
      toast.success('Gym Updated Successfully');
      fetchGymData();
    } catch (error) {
      toast.error('Error updating gym data:', error);
    }
  };

  const handleLocationSelect = (location) => {
    setLat(location.lat);
    setLng(location.lng);
    setModalData((prevData) => ({
      ...prevData,
      latitude: location.lat,
      longitude: location.lng,
    }));
  };

  const handleImageUpload = async (event) => {
    const formData = new FormData();
  
    for (const file of event.target.files) {
      try {
        // Compress the image
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.5, // Maximum size is now 500KB to compress more
          maxWidthOrHeight: 600, // Resize the image to 600px if it exceeds this dimension
          useWebWorker: true, // Use web worker for better performance
          initialQuality: 0.85, // Quality is set to 85% for better image quality
          fileType: 'image/jpeg', // Ensure the compressed file is converted to JPEG
        
        });
  
        // Append the compressed image to formData
        formData.append('images', compressedFile);
      } catch (error) {
        toast.error('Error compressing image:', error);
        return;
      }
    }
  
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/gym-images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
        },
      });
      fetchImages();
      toast.success('Images Uploaded Successfully');
    } catch (error) {
      toast.error('Error uploading images:', error);
    }
  };
  const handleMapClose = () => {
    setShowMap(false);
    setIsMapModalOpen(false); // Set to false when the map modal is closed
  };
  const handleImageDelete = async (imageId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/gym-images/${imageId}`, {
        headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
      });
      fetchImages();
      toast.success('Image Deleted Successfully');
    } catch (error) {
      toast.error('Error deleting image:', error);
    }
  };


  const handleUseCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude", latitude);
          console.log("longitude", longitude);
          setLat(latitude);
          setLng(longitude);
          setModalData((prevData) => ({
            ...prevData,
            latitude,
            longitude,
          }));
          toast.success('Coordinates set to current location.');
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error('Location access denied or unavailable.');
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };
  const goToNextPage = () => navigate('/subscription');
  const goToPreviousPage = () => navigate('/slots');

  return (
    <>
      <Header />
      <Container className="merged-gym-page">
        {/* Navigation Buttons in a ButtonGroup */}
        <ButtonGroup className="d-flex justify-content-center my-3">
          <Button variant="secondary" size="sm" onClick={goToPreviousPage}>Previous page</Button>
          <Button variant="primary" size="sm" onClick={goToNextPage}>Next Page</Button>
        </ButtonGroup>

        <Row>
          {/* Image Gallery Section */}
          <Col md={6} className="gallery-col">
            <Card className="shadow-lg mb-4">
              <Card.Header className="bg-success text-white">
                <h2>Gym Image Gallery</h2>
              </Card.Header>
              <Card.Body>
                <label className="upload-button mb-3">
                  <input
                    type="file"
                    multiple
                    onChange={handleImageUpload}
                    className="upload-input"
                  />
                  <span className="btn btn-success">Upload New Images</span>
                </label>
                <div className="gallery">
                  {images.length > 0 ? (
                    images.map((image) => (
                      <div key={image.id} className="gallery-item">
                        <img src={image.imageUrl} alt={`Gym Image ${image.id}`} />
                        <button className="delete-button" onClick={() => handleImageDelete(image.id)}>✖</button>
                      </div>
                    ))
                  ) : (
                    <div className="no-images">No images available. Upload some to get started!</div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Gym Details Section */}
          <Col md={6} className="gym-details-col">
            <Card className="shadow-lg mb-4">
            <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                    <div>
                      <h2>{gymData?.name || 'Loading...'}</h2>
                      {gymData?.id && <small>ID: {gymData.gym_unique_id}</small>} {/* Display the gym ID */}
                    </div>
                    <Button variant="light" onClick={handleShowEditModal}>Modify Details</Button>
                  </Card.Header>
              <Card.Body>
                <Card.Title>Gym Description</Card.Title>
                <Card.Text>{gymData?.description}</Card.Text>
                <Card.Title>Gym Location</Card.Title>
                <Card.Text>
                  {gymData?.addressLine1}, {gymData?.addressLine2}<br />
                  {gymData?.city}, {gymData?.state}, {gymData?.country}, {gymData?.pinCode}
                </Card.Text>
                <Card.Title>Coordinates</Card.Title>
                <Card.Text>
                  Latitude: {gymData?.latitude}<br />
                  Longitude: {gymData?.longitude}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Modal for Editing Gym Details */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Gym Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {Object.keys(modalData).map((key) => (
                <Form.Group controlId={key} key={key}>
                  <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                  {(key != "city" && key != "state") && <Form.Control
                    type={key.includes('latitude') || key.includes('longitude') ? 'number' : 'text'}
                    name={key}
                    value={modalData[key]}
                    onChange={handleChange}
                  />}

                  {(key == "city") && <Form.Group className="mb-3">
            
              <Form.Select
                name="city"
                value={modalData.city}
                onChange={handleChange}
                disabled={!modalData.state}
              >
                <option>Select City</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>}

            {(key == "state") && <Form.Group className="mb-3">
            
              <Form.Select
                name="state"
                value={modalData.state}
                onChange={handleChange}
              >
                <option>Select State</option>
                {states.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </Form.Select>
          </Form.Group>}
                </Form.Group>
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
         
            <>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
            {/* <Button variant="secondary" onClick={handleUseCurrentLocation}>Use Current Location</Button> */}
            <Button variant="primary" onClick={handleSave}>Set Location</Button>
            </>
    
          </Modal.Footer>
        </Modal>

        {/* Show Google Map */}
        {showMap && (
          <Modal show={showMap} onHide={() => setShowMap(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Select Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MapComponent onLocationSelect={handleLocationSelect} pinCode={modalData.pinCode} lat={lat} lng={lng} setCurrentLocation={handleUseCurrentLocation}/>
              
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleMapSave}>Save Changes</Button>
            <Button variant="secondary" onClick={handleMapClose}>Close</Button>
            </Modal.Footer>
          </Modal>
        )}

        <ToastContainer />
      </Container>
    </>
  );
};

export default MergedGymPage;