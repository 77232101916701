import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginWithPin.css';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false); // New state for tracking email sent
  const loginId = Cookies.get('auth');

  useEffect(() => {
    // Function to verify the token by making an API call
    const verifyToken = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-token`,
          {},
          {
            headers: {
              Authorization: loginId,
            },
          }
        );

        // If the token is valid, redirect to the profile page
        if (response.data.message === 'Token is valid') {
          navigate('/profile');
        }
      } catch (error) {
        console.error('Error verifying token:', error.response.status);
        // Clear any stale token
        Cookies.remove('auth');
      }
    };

    // Call the function if loginId exists
    if (loginId) {
      verifyToken();
    }
  }, [loginId, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/send-verify-link`,
        {
          email: loginData.email,
        }
      );
      setEmailSent(true); 
    } catch (error) {
      if (error.response.status === 403) {
        setEmailSent(true); // Update the view to show email sent message
      } else {
        toast.error('Invalid email or password. Please try again.');
      }
    }
  };

  return (
    <div className="login-page">
      <Header />
      
      <div className="hero-overlay">
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow-lg">
                <div className="card-body">
                
                  {emailSent ? (
                    // View for email sent
                    <p className="text-success">
                      A verification email has been sent to your email address. Please click on the link to verify your email and login.
                    </p>
                  ) : (
                    // Default view
                    <>
                      <p>
                         Your email address is not verified. Please verify your email address.
                      </p>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                          <label className="text-light">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={loginData.email}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter your email"
                          />
                        </div>

                        {error && (
                          <div className="alert alert-danger">{error}</div>
                        )}
                        <button
                          type="submit"
                          className="btn btn-warning w-100 mb-2"
                        >
                          Send verification link
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default VerifyEmail;
